
import Vue from 'vue';
import item from '../field/index.vue';
import { columnSizeDef } from '../field/columnSize';

export default Vue.extend({
  name: 'SectionColumn',
  components: { item },
  props: {
    /**
     * オブジェクト情報
     */
    objectInfo: { type: Object, required: true },
    /**
     * 編集モード
     */
    editMode: { type: Boolean },
    /**
     * 値変更時
     */
    handleChangeValue: { type: Function, default: null },
    /**
     * セクション
     */
    section: { type: Object, required: false, default: () => ({}) },
    /**
     * 変更禁止項目
     */
    readonlyFieldNames: { type: Array, required: false, default: () => [] },
    /**
     * 入力チェック関数
     */
    customValidations: { type: Array, required: false, default: () => [] },
  },
  computed: {
    /**
     * 表示するカラム設定
     */
    cols(): string {
      if (this.$vuetify.breakpoint.mobile) {
        return '12';
      }
      const index = 12 / Number(this.section.column);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (columnSizeDef as any)[index][this.$vuetify.breakpoint.name];
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customValidation(fieldPath: string): any {
      return (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.customValidations?.find((c: any) => c.fieldName === fieldPath) ||
        null
      );
    },
  },
});
