
import Vue from 'vue';
import { provided } from '..';
import { Autolinker } from 'autolinker';
export default Vue.extend({
  inject: ['objectName', 'reactive'],
  data: () => ({
    items: [] as {
      /**
       * @label=カスタムオブジェクト ID
       */
      Id: string;
      /**
       * @label=対象オブジェクトID
       */
      ObjectId: string;
      /**
       * @label=対象オブジェクト名
       */
      ObjectName: string;
      /**
       * @label=本文
       */
      Body: string | null;
      /**
       * @label=削除
       */
      IsDeleted: boolean | null;
      CreatedById: string | null;
      LastModifiedById: string | null;
      /**
       * @label=最終更新日
       */
      LastModifiedDate: Date | null;
      /**
       * @label=作成日
       */
      CreatedDate: Date;
    }[],
    inputText: '',
    Autolinker,
  }),
  computed: {
    canPost(): boolean {
      const { reactive } = this as any;
      return (
        reactive.value?.Version__c === '0' ||
        reactive.latest?.Id === reactive.value?.Id
      );
    },
    userId() {
      return this.$store.state.user.user.Id;
    },
  },
  watch: {
    items: {
      handler(to) {
        this.$emit('updated-comments', to);
      },
      deep: true,
    },
  },
  async mounted() {
    await this.load();
  },
  methods: {
    /**
     * コメントを投稿する
     */
    async postComment() {
      const { controller } = (this as any).$pageProperty;
      const { reactive, objectName } = (this as unknown) as provided;
      await this.$store.dispatch(
        'loading/register',
        this.$con.invoke({
          controller,
          method: 'postComment',
          params: {
            data: {
              Body: this.inputText,
              ObjectId: reactive.ParentId__c,
              ObjectName: objectName,
            },
            value: reactive.value,
            currentUrl: location.href,
            createdUserId: this.$store.state.user.user.Id
          },
        }),
      );
      await this.$store.dispatch('loading/register', this.load());
      this.inputText = '';
    },
    async load() {
      const { controller } = (this as any).$pageProperty;
      const { reactive, objectName } = this as any;
      this.items = await this.$store.dispatch(
        'loading/register',
        this.$con.invoke({
          controller,
          method: 'getComment',
          params: {
            ObjectId: reactive.ParentId__c,
            ObjectName: objectName,
            // LastModifiedDate: !this.canPost && reactive.value.LastModifiedDate,
          },
        }),
      );
    },
  },
});
