
import Vue from 'vue';
export default Vue.extend({
  props: {
    /**
     * セクションタイトル
     */
    title: { type: String, required: false, default: '' },
    /**
     * trueの場合、編集時にセクションタイトルを表示します
     */
    editHeading: { type: Boolean, required: false, default: true },
    /**
     * trueの場合、非編集時にセクションタイトルを表示します
     */
    detailHeading: { type: Boolean, required: false, default: true },
    /**
     * trueがセットされた場合、編集モードになります
     */
    editMode: { type: Boolean, required: false, default: null },
    /**
     * trueの場合、セクションを非表示にします
     */
    hidden: { type: Boolean, required: false, default: false },
    /**
     * trueの場合、セクションを一時的に非表示にします
     */
    notShow: { type: Boolean, required: false, default: false },
  },
  computed: {
    /**
     * trueの場合、セクションタイトルを表示します。
     */
    showSectionTitle(): boolean {
      return (
        (this._editMode && this.editHeading) ||
        (!this._editMode && this.detailHeading)
      );
    },
    _editMode(): boolean {
      return this.editMode;
    },
  },
});
