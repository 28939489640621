
import Vue, { PropType } from 'vue';
import { mapActions } from 'vuex';
import { LayoutInfomation } from '.';

import formSection from './section/index.vue';
import SectionColumn from './section/SectionColumn.vue';

export default Vue.extend({
  components: { formSection, SectionColumn },
  props: {
    /**
     * フォーム名
     */
    name: { type: String, required: true },
    /**
     * レイアウト情報
     */
    layout: { type: Object as PropType<LayoutInfomation>, required: true },
    /**
     * 非表示セクション
     */
    hiddenSectionNames: { type: Array, required: false, default: () => [] },
    /**
     * 非表示項目
     */
    hiddenFieldNames: { type: Array, required: false, default: () => [] },
    /**
     * ？非表示項目(v-showで制御)
     */
    notShowSectionNames: { type: Array, required: false, default: () => [] },
    /**
     * 変更禁止セクション
     */
    readonlySectionNames: { type: Array, required: false, default: () => [] },
    /**
     * 変更禁止項目
     */
    readonlyFieldNames: { type: Array, required: false, default: () => [] },
    /**
     * 入力チェック関数
     */
    customValidations: { type: Array, required: false, default: () => [] },
    /**
     * オブジェクト情報
     */
    objectInfo: { type: Object, required: true },
    /**
     * 編集モード
     */
    editMode: { type: Boolean },
    /**
     * スケルトンローディング中
     */
    skeletonLoading: { type: Boolean },
    /**
     * フォームデータ
     */
    value: { type: Object, default: () => ({}) },
    /**
     * 必須項目の表示をするか
     */
    showRequiredHelp: { type: Boolean, default: true },
    /**
     * カードのプロパティ
     */
    cardProps: { type: Object, default: () => ({}) },
  },
  computed: {
    _value: {
      get(): Object {
        return this.value;
      },
      set(val: Object) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    handleChangeValue(event: unknown) {
      this.$emit('change', event);
    },
    handleSubmitRaw(form: unknown) {
      this.$emit('submit-raw', form);
    },
  },

  ...mapActions('snackbar', ['openErrorSnackBar']),
});
